<template>
  <div class="showcase-table">
    <ejs-grid
      ref="grid"
      :dataSource="dm"
      :allowSorting="true"
      :allowPaging="true"
      :query="query"
      :pageSettings="pageSettings"
    >
      <e-columns>
        <e-column
          field="id"
          headerText=""
          isPrimaryKey="{true}"
          :template="imageTemplate"
          width="90"
          textAlign="Center"
        ></e-column>
        <e-column
          :template="nameTemplate"
          :field="`ppl.productproducer.translations.${lang}.name`"
          :headerText="$t('models.productproducer.name')"
        ></e-column>
        <e-column
          :template="categoryTemplate"
          :field="`ppl.productproducer.category.translations.${lang}.name`"
          :headerText="$t('models.productproducer.category')"
        ></e-column>
        <e-column
          field="ppl.productproducer.producer.role.name"
          :template="producerTemplate"
          :headerText="$t('models.productproducer.producer')"
          width="240"
        ></e-column>
        <e-column
          field="ppm.productproducer.pw"
          :template="certificationsTemplate"
          :headerText="$t('models.productproducer.flags')"
          width="240"
        ></e-column>
        <e-column
          field="ppm.price"
          :template="priceTemplate"
          :headerText="$t('models.productproducer.price')"
          width="240"
        ></e-column>
        <e-column
          :template="quantityTemplate"
          :headerText="$t('models.cart.qta')"
          width="220"
        ></e-column>
      </e-columns>
    </ejs-grid>

    <CRow>
      <CCol xs="2" sm="2" md="1" lg="1" class="ml-auto">
        <UiSelect
          :value.sync="intendedPageSize"
          :options="[12, 50, 100, 200]"
          custom
        />
      </CCol>
    </CRow>
  </div>
</template>

<script>
import Vue from "vue";
import { mapState, mapGetters } from "vuex";
import { Sort, Page } from "@syncfusion/ej2-vue-grids";
import { Query, Predicate } from "@syncfusion/ej2-data";
import { GetDataManagerNew } from "../../../ds";
// import {
//   MarketProductTableVariantCategory,
//   MarketProductTableVariantImage,
//   MarketProductTableVariantPrice,
//   MarketProductTableVariantTitle,
//   MarketProductTableVariantQuantity,
//   MarketProductTableVariantProducer,
// } from "./index";
import { ORDER_STATUS } from "../../../config/global";
import { getTranslation } from "../../../helpers/common";
import toUpper from "lodash/toUpper";
import { formatPrice } from "../../../helpers/common";
import { getCartItemByPpmId, getOptionLabel } from "../../../helpers/common";
import { getPriceUnitOptions, getPwOptions } from "../../../helpers/options";
import get from "lodash/get";
import UiSelect from "../../../common/form/UiSelect";

export default {
  name: "MarketShowcaseTable",

  components: {
    UiSelect,
  },

  provide: {
    grid: [Sort, Page],
  },

  props: {
    filter: {
      type: Object,
      default: () => {
        return {};
      },
    },
    bus: { type: Object },
  },

  computed: {
    ...mapGetters("connections", ["currencySymbol"]),

    lang: function () {
      return this.$i18n.locale;
    },
  },

  mounted() {
    const self = this;
    this.bus.$on("onSearch", (filter) => {
      self.loadProducts(filter);
    });

    this.$root.$on("onCartAdd", this.onCartAdd);
  },

  beforeDestroy() {
    this.bus.$off("onSearch");
    this.$root.$off("onCartAdd", this.onCartAdd);
  },

  data: function () {
    const familyId = this.$store.state.connections.current.family.id;
    const marketId = this.$store.state.shop.market.market.id;
    const dm = GetDataManagerNew("family_market_variants", [
      familyId,
      marketId,
    ]);
    return {
      dm: dm,
      intendedPageSize: parseInt(localStorage.getItem("l_prod_rows")) || 12,
      pageSettings: {
        pageSize: parseInt(localStorage.getItem("l_prod_rows")) || 12,
      },
      query: new Query(),

      imageTemplate: function () {
        return {
          template: Vue.component("columnTemplate", {
            template: `<div :class="{ opacity50: disabled }">
                <figure class="table-figure">
                  <img class="border rounded" :src="getImage()" height="50" />
                </figure>
              </div>`,
            computed: {
              disabled() {
                return this.data.status === ORDER_STATUS.CANCELED;
              },
            },

            methods: {
              getImage() {
                const variantImage = get(this.data, `ppl.image.image_thumb`);
                if (variantImage) {
                  return variantImage;
                }
                const productImage = get(
                  this.data,
                  `ppl.productproducer.image.image_thumb`
                );
                if (productImage) {
                  return productImage;
                }

                return "https://placehold.co/100x75?text=\u221e";
              },
            },
          }),
        };
      },

      // imageTemplate: () => {
      //   return {
      //     template: {
      //       extends: MarketProductTableVariantImage,
      //     },
      //   };
      // },

      nameTemplate: function () {
        return {
          template: Vue.component("columnTemplate", {
            template: `<div :class="{ opacity50: disabled }" class="productTemplateTitle" @click="onCardClick(getProductId())">
                <strong>{{ getProductName() }}</strong>
                <div>{{ getVariantName() }}</div>
              </div>`,
            computed: {
              disabled() {
                return this.data.status === ORDER_STATUS.CANCELED;
              },
            },

            methods: {
              onCardClick(productId) {
                const familyId = this.$route.params.familyId;
                const marketId = this.$route.params.marketId;
                this.$router.push({
                  name: "UserMarketProduct",
                  params: { familyId, marketId, productId },
                });
              },
              getProductName() {
                const translations = get(
                  this.data,
                  "ppl.productproducer.translations"
                );
                return getTranslation(translations, this.$i18n.locale);
              },
              getVariantName() {
                const translations = get(this.data, "ppl.translations");
                return getTranslation(translations, this.$i18n.locale);
              },
              getProductId() {
                return get(this.data, `ppl.productproducer.id`);
              },
            },
          }),
        };
      },

      // nameTemplate: () => {
      //   return { template: MarketProductTableVariantTitle };
      // },

      categoryTemplate: function () {
        return {
          template: Vue.component("columnTemplate", {
            template: `<div>
                <div class="ui-type-caption text-gray-600">
                  {{ toUpper(parentCategory()) }}
                </div>
                <div>{{ category() }}</div>
              </div>`,

            methods: {
              toUpper: toUpper,
              parentCategory() {
                return getTranslation(
                  get(
                    this.data,
                    "ppl.productproducer.category.parent.translations",
                    {}
                  ),
                  this.$i18n.locale
                );
              },
              category() {
                return getTranslation(
                  get(
                    this.data,
                    "ppl.productproducer.category.translations",
                    {}
                  ),
                  this.$i18n.locale
                );
              },
            },
          }),
        };
      },

      // categoryTemplate: () => {
      //   return { template: MarketProductTableVariantCategory };
      // },

      producerTemplate: function () {
        return {
          template: Vue.component("columnTemplate", {
            template: `<div>
                <strong>{{ getProducertName() }}</strong>
              </div>`,

            methods: {
              getProducertName() {
                return get(this.data, `ppl.productproducer.producer.role.name`);
              },
            },
          }),
        };
      },

      // producerTemplate: () => {
      //   return { template: MarketProductTableVariantProducer };
      // },

      priceTemplate: function () {
        return {
          template: Vue.component("columnTemplate", {
            template: `<div>
                <strong>{{ getPrice() }}</strong>
                <small v-if="basePriceUnit()"><br />{{ basePriceUnit() }}</small>
                <span class="ml-2 text-warning" v-if="needPriceCorrection()">
                  <font-awesome-icon icon="balance-scale" />
                </span>
              </div>`,

            computed: {
              ...mapGetters("connections", ["currencySymbol"]),
            },

            methods: {
              getPrice() {
                return formatPrice(
                  this.data.ppm.price,
                  this.currencySymbol
                ).format();
              },
              basePriceUnit() {
                const price_base_unit = get(this.data, "price_base_unit");

                if (price_base_unit === null) return "";

                const unitOptions = getPriceUnitOptions(this.$i18n);
                let weight_unit = null;
                weight_unit = getOptionLabel(price_base_unit.unit, unitOptions);
                const unitPrice = formatPrice(
                  price_base_unit.price,
                  this.currencySymbol
                ).format();
                return unitPrice + " / " + weight_unit;
              },
              needPriceCorrection() {
                return (
                  get(this.data, "ppl.pw", false) ||
                  get(this.data, "ppl.productproducer.pw", false)
                );
              },
            },
          }),
        };
      },

      certificationsTemplate: function () {
        return {
          template: Vue.component("columnTemplate", {
            template: `<div>
                <div v-if="certifications()" v-html="certifications()"></div>
              </div>`,

            methods: {
              certifications() {
                const certifications = get(
                  this.data,
                  "ppl.productproducer.certifications",
                  []
                );
                let cert_string = "";
                if (certifications.length > 0) {
                  for (var i = 0; i < certifications.length; i++) {
                    // certification logos
                    /*
                    cert_string += `<img src="${certifications[i].logo_thumb}"
                   alt="${certifications[i].name}"
                   title="${certifications[i].name}"
                   class="productTemplateCertifications"/>`;
                   */
                    // certification names
                    cert_string += `${certifications[i].name}`;
                    if (i < certifications.length - 1) {
                      cert_string += ", ";
                    }
                  }
                }
                return cert_string;
              },
            },
          }),
        };
      },

      // priceTemplate: () => {
      //   return { template: MarketProductTableVariantPrice };
      // },

      quantityTemplate: function () {
        return {
          template: Vue.component("columnTemplate", {
            template: `<form action="" @submit.prevent="onSubmit" class="d-flex">
                <CInput
                  class="mb-0 mr-1"
                  :append="suffix()"
                  v-model="quantity"
                  type="number"
                  min="0"
                  :step="step()"
                />
                <CButton color="primary" type="submit">
                  <font-awesome-icon icon="shopping-cart" />
                </CButton>
              </form>`,

            data() {
              return {
                data: {},
                quantity: 0,
              };
            },

            mounted() {
              const { cart, data } = this;
              if (data && cart) {
                const v = getCartItemByPpmId(cart, get(data, "ppm.id"));
                this.quantity = v ? v.qta : 0;
              }
            },

            computed: {
              ...mapState("shop", ["cart"]),
            },

            watch: {
              cart() {
                const { cart, data } = this;
                if (data && cart) {
                  const v = getCartItemByPpmId(cart, get(data, "ppm.id"));
                  this.quantity = v ? v.qta : 0;
                }
              },
            },

            methods: {
              suffix() {
                const pp = get(this.data, "ppl.productproducer");
                const pw_option = get(pp, "extra.pw_option", 1);
                return pw_option === 3
                  ? `${getOptionLabel(
                      pp.pw_unit,
                      getPriceUnitOptions(this.$i18n)
                    )}`
                  : `${getOptionLabel(
                      pw_option,
                      getPwOptions(this.$i18n),
                      "short"
                    )}`;
              },

              step() {
                const isWeightProduct =
                  get(this.data, "ppl.productproducer.pw") === true;
                return isWeightProduct ? "0.1" : "1";
              },

              onSubmit(e) {
                this.$root.$emit("onCartAdd", {
                  row: this.data,
                  event: e,
                  data: { quantity: this.quantity },
                });
              },
            },
          }),
        };
      },

      // quantityTemplate: () => {
      //   return { template: MarketProductTableVariantQuantity };
      // },
    };
  },
  methods: {
    loadProducts(filter) {
      let query = new Query();
      let predicate = new Predicate("id", "greaterthanorequal", 0);

      const { search, categoryId, producerId, certificationsIds } = filter;
      const lang = this.$i18n.locale;

      if (search !== "") {
        query = query.search(search, [
          `ppl.translations.${lang}.name`,
          `ppl.productproducer.translations.${lang}.name`,
          `ppl.productproducer.producer.role.name`,
        ]);
      }

      if (
        categoryId &&
        categoryId !== null &&
        !isNaN(parseInt(categoryId, 10))
      ) {
        predicate = predicate.and(
          "ppl.productproducer.category_id",
          "equal",
          categoryId
        );
      }
      if (
        producerId &&
        producerId !== null &&
        !isNaN(parseInt(producerId, 10))
      ) {
        predicate = predicate.and(
          "ppl.productproducer.producer.id",
          "equal",
          producerId
        );
      }

      if (Array.isArray(certificationsIds) && certificationsIds.length) {
        let certificationsPredicate = null;
        certificationsIds.forEach((el) => {
          if (certificationsPredicate === null) {
            certificationsPredicate = new Predicate(
              "pp__pp__certifications__id",
              "equal",
              el.value
            );
          } else {
            certificationsPredicate = certificationsPredicate.or(
              "pp__pp__certifications__id",
              "equal",
              el.value
            );
          }
        });
        predicate = predicate.and(certificationsPredicate);
      }

      query = query.where(predicate);
      query.page(1, 12);

      this.query = query;
    },

    onCartAdd({ row, data }) {
      const self = this;

      const ppm_id = row.ppm.id;
      const quantity = data.quantity;

      this.$store
        .dispatch("shop/setCartQuantity", { items: [{ ppm_id, quantity }] })
        .then((response) => {
          self.$store.dispatch("toaster/add", {
            title: "OK!",
            text: self.$t("family.market.showcase.cart.msg_cart_update"),
            color: "success",
            autohide: true,
          });
        });
    },
  },
  watch: {
    intendedPageSize: function (val) {
      console.log(val, this.intendedPageSize, this.pageSettings.pageSize);
      this.pageSettings.pageSize = val;
      const gridInstance = this.$refs.grid.ej2Instances;
      gridInstance.setProperties({ pageSettings: this.pageSettings });
      localStorage.setItem("l_prod_rows", parseInt(val));
    },
  },
};
</script>

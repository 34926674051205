<template>
  <CModal :show="show" :closeOnBackdrop="false" :centered="true" size="xl">
    <template #header>
      <h5 class="modal-title">{{ title }}</h5>
    </template>

    <CAlert v-if="error !== null" color="danger" class="mb-3">
      {{ error }}
    </CAlert>

    <form action="" @submit.prevent="onSubmit" ref="form">
      <CSelect
        :label="$t('family.orders.message.cause')"
        :options="messageTypeOptions"
        :value.sync="messageType"
        custom
      />

      <div class="form-group">
        <label for="message">{{ $t("family.orders.message.text") }}</label>
        <textarea
          :class="{ 'form-control': true, 'is-invalid': haveFieldError('msg') }"
          id="message"
          v-model="message"
          rows="9"
        ></textarea>
        <div class="invalid-feedback d-block" v-if="haveFieldError('msg')">
          {{ getFieldError("msg") }}
        </div>
      </div>
      <div v-show="messageType === 'PRODUCTS'">
        <CRow class="mb-3">
          <CCol
            ><strong>{{
              $t("family.orders.message.msg_selectproducts")
            }}</strong></CCol
          >
        </CRow>
        <div class="details-table-wrapper">
          <ejs-grid
            ref="grid"
            :dataSource="details"
            :allowSelection="true"
            :selectionSettings="selectionSettings"
            @headerCellInfo="headerCellInfo"
          >
            <e-columns>
              <e-column type="checkbox" width="40" textAlign="left"></e-column>
              <e-column
                field="id"
                headerText=""
                isPrimaryKey="{true}"
                :template="imageTemplate"
                width="90"
                textAlign="Center"
              ></e-column>
              <e-column
                :template="nameTemplate"
                :headerText="$t('models.productproducer.name')"
              ></e-column>
              <e-column
                :template="producerTemplate"
                width="240"
                :headerText="$t('models.productproducer.producer')"
              ></e-column>
              <e-column
                :template="quantityTemplate"
                :headerText="$t('models.cart.qta')"
                textAlign="right"
                width="100"
              ></e-column>
              <e-column :template="statusTemplate" width="200"></e-column>
              <e-column
                :template="totalTemplate"
                textAlign="right"
                :headerText="$t('models.cart.total')"
                width="120"
              ></e-column>
            </e-columns>
          </ejs-grid>
        </div>
      </div>
    </form>

    <template #footer>
      <CButton color="primary" variant="outline" @click.prevent="close">{{
        $t("common.editor.cancel")
      }}</CButton>
      <vue-ladda
        :disabled="isButtonDisabled"
        :loading="loading"
        data-style="zoom-in"
        button-class="btn btn-primary px-4"
        @click.prevent="onSubmit"
        >{{ $t("common.editor.send") }}</vue-ladda
      >
    </template>
  </CModal>
</template>

<script>
import Vue from "vue";
import get from "lodash/get";
import EventBus from "../../../helpers/EventBus";
import { mapGetters } from "vuex";
import { ORDER_STATUS } from "../../../config/global";
import {
  getTranslation,
  getOptionLabel,
  formatPrice,
} from "../../../helpers/common";
import { getPriceUnitOptions, getPwOptions } from "../../../helpers/options";
// import MarketOrderTableVariantImage from "./MarketOrderTableVariantImage";
// import MarketOrderTableVariantQuantity from "./MarketOrderTableVariantQuantity";
// import MarketOrderTableVariantTitle from "./MarketOrderTableVariantTitle";
// import MarketOrderTableVariantProducer from "./MarketOrderTableVariantProducer";
// import MarketOrderTableVariantStatus from "./MarketOrderTableVariantStatus";
// import MarketOrderTableVariantTotal from "./MarketOrderTableVariantTotal";

export default {
  name: "MarketOrderMessageModal",

  data() {
    return {
      loading: false,
      error: null,
      messageType: "NULL",
      message: "",

      messageTypeOptions: [
        {
          value: "NULL",
          label: this.$t("family.orders.message.cause_options.choose"),
        },
        {
          value: "DELIVERY",
          label: this.$t("family.orders.message.cause_options.delivery"),
        },
        {
          value: "PRODUCTS",
          label: this.$t("family.orders.message.cause_options.products"),
        },
      ],

      selectionSettings: {
        persistSelection: false,
        enableToggle: true,
        checkboxOnly: true,
      },

      imageTemplate: function () {
        return {
          template: Vue.component("columnTemplate", {
            template: `<div :class="{ opacity50: disabled }">
                <figure class="table-figure">
                  <img class="border rounded" :src="getImage()" height="50" />
                </figure>
              </div>`,

            computed: {
              disabled() {
                return this.data.status === ORDER_STATUS.CANCELED;
              },
            },

            methods: {
              getImage() {
                const image = get(this.data, `ppm.img.image_thumb`);
                if (image) {
                  return image;
                }
                return "https://picsum.photos/36";
              },
            },
          }),
        };
      },

      nameTemplate: function () {
        return {
          template: Vue.component("columnTemplate", {
            template: `<div :class="{ opacity50: disabled }">
                <strong>{{ getProductName() }}</strong>
                <div>{{ getVariantName() }}</div>
              </div>`,

            computed: {
              lang: function () {
                return this.$i18n.locale;
              },
              disabled() {
                return this.data.status === ORDER_STATUS.CANCELED;
              },
            },
            methods: {
              getProductName() {
                const translations = get(
                  this.data,
                  "ppm.lot.productproducer.translations"
                );
                return getTranslation(translations, this.lang);
              },
              getVariantName() {
                const translations = get(this.data, "ppm.lot.translations");
                return getTranslation(translations, this.lang);
              },
            },
          }),
        };
      },

      quantityTemplate: function () {
        return {
          template: Vue.component("columnTemplate", {
            template: `<div class="text-right" :class="{ opacity50: disabled }">
                <div class="ui-type-body font-weight-bold">
                  <span class="mr-2" :class="{ strikethrough: disabled }">{{
                    presentQuatity()
                  }}</span>
                </div>
              </div>`,

            computed: {
              disabled() {
                return this.data.status === ORDER_STATUS.CANCELED;
              },
            },
            methods: {
              suffix() {
                const pp = get(this.data, "ppm.lot.productproducer");
                const pw_option = get(pp, "extra.pw_option", 1);
                if (pw_option === 3) {
                  return `${getOptionLabel(
                    pp.pw_unit,
                    getPriceUnitOptions(this.$i18n)
                  )}`;
                } else {
                  return `${getOptionLabel(
                    pw_option,
                    getPwOptions(this.$i18n),
                    "short"
                  )}`;
                }
              },

              presentQuatity() {
                return `${this.data.qta} ${this.suffix()}`;
              },
            },
          }),
        };
      },

      statusTemplate: function () {
        return {
          template: Vue.component("columnTemplate", {
            template: `<div>
                <div
                  v-if="status.label !== ''"
                  :class="getBadgeClasses()"
                  @click.prevent="onClick"
                >
                  {{ status.label }}
                </div>
                <div v-if="status.detail">{{ status.detail }}</div>
              </div>`,

            computed: {
              status() {
                return this.orderDetailStatus(this.data);
              },
            },

            methods: {
              pwUnit() {
                const pp = get(this.data, "ppm.lot.productproducer");
                const pw_option = get(pp, "extra.pw_option", 1);
                if (pw_option === 3) {
                  return `${getOptionLabel(
                    pp.pw_unit,
                    getPriceUnitOptions(this.$i18n)
                  )}`;
                } else {
                  return `${getOptionLabel(
                    pw_option,
                    getPwOptions(this.$i18n),
                    "short"
                  )}`;
                }
              },

              orderDetailStatus(orderDetail) {
                const { status, incident } = orderDetail;
                const {
                  CREATE,
                  PAYMENT_WAIT,
                  APPROVAL,
                  CANCELED,
                  ALERT,
                  PRODUCER_IN_CHARGE,
                  MARKET_ARRIVED,
                  MARKET_IN_CHARGE,
                  ISLAND_ARRIVED,
                  ISLAND_IN_CHARGE,
                  FAMILY_IN_CHARGE,
                  DELIVERED,
                } = ORDER_STATUS;

                // incident
                if (incident !== null) {
                  if (
                    get(incident, "detail.extra.action", "") === "" ||
                    get(incident, "close", false) === false
                  ) {
                    return {
                      label: this.$t("family.orders.order.incident.working"),
                      color: "warning",
                    };
                  }

                  if (
                    get(incident, "detail.extra.action", "") ===
                    INCIDENT_ACTIONS.DISCOUNT
                  ) {
                    return {
                      label: this.$t("family.orders.order.incident.discount"),
                      color: "blue",
                      detail: "",
                    };
                  }

                  if (
                    get(incident, "detail.extra.action", "") ===
                    INCIDENT_ACTIONS.REPLACEMENT
                  ) {
                    return {
                      label: this.$t("family.orders.order.incident.replaced"),
                      color: "blue",
                      detail: get(incident, "detail.extra.replacedWith", ""),
                    };
                  }

                  if (
                    get(incident, "detail.extra.action", "") ===
                    INCIDENT_ACTIONS.CHANGE_WEIGHT
                  ) {
                    return {
                      label: this.$t(
                        "family.orders.order.incident.change_weight"
                      ),
                      color: "warning",
                      detail: `${get(
                        incident,
                        "detail.extra.newWeight",
                        ""
                      )} ${this.pwUnit()}`,
                    };
                  }

                  if (
                    get(incident, "detail.extra.action", "") ===
                    INCIDENT_ACTIONS.CANCELLED
                  ) {
                    return {
                      label: this.$t("family.orders.order.incident.deleted"),
                      color: "danger",
                      detail: "",
                    };
                  }

                  if (
                    get(incident, "detail.extra.action", "") ===
                    INCIDENT_ACTIONS.CANCELLED_FOR
                  ) {
                    return {
                      label: this.$t(
                        "family.orders.order.incident.deleted_for"
                      ),
                      color: "warning",
                      detail: "",
                    };
                  }
                }

                // status

                if ([CREATE, PAYMENT_WAIT, APPROVAL].includes(status)) {
                  return {
                    label: this.$t("family.orders.order.status.approve"),
                    color: "info",
                    detail: "",
                  };
                }

                if ([CANCELED].includes(status)) {
                  return {
                    label: this.$t("family.orders.order.status.cancelled"),
                    color: "danger",
                    detail: "",
                  };
                }

                if ([ALERT].includes(status)) {
                  return {
                    label: this.$t("family.orders.order.status.incident"),
                    color: "warning",
                    detail: "",
                  };
                }

                if (
                  [
                    PRODUCER_IN_CHARGE,
                    MARKET_ARRIVED,
                    MARKET_IN_CHARGE,
                  ].includes(status)
                ) {
                  return {
                    label: this.$t("family.orders.order.status.working"),
                    color: "success",
                    detail: "",
                  };
                }

                if ([ISLAND_ARRIVED].includes(status)) {
                  return {
                    label: this.$t("family.orders.order.status.moving"),
                    color: "blue",
                  };
                }

                if ([ISLAND_IN_CHARGE].includes(status)) {
                  return {
                    label: this.$t("family.orders.order.status.delivery"),
                    color: "blue",
                    detail: "",
                  };
                }

                if ([FAMILY_IN_CHARGE].includes(status)) {
                  return {
                    label: this.$t("family.orders.order.status.delivery_done"),
                    color: "success",
                    detail: "",
                  };
                }

                if ([DELIVERED].includes(status)) {
                  return {
                    label: this.$t("family.orders.order.status.completed"),
                    color: "success",
                    detail: "",
                  };
                }

                return { label: "", color: "", detail: "" };
              },

              getBadgeClasses() {
                const { status } = this;
                const classes = [`badge badge-${status.color}`];
                if (this.openIncidentDetails && this.haveIncident()) {
                  classes.push("cursor-pointer");
                }
                return classes.join(" ");
              },

              haveIncident() {
                return get(this.data, "incident", null) !== null;
              },

              onClick() {
                if (this.openIncidentDetails && this.haveIncident()) {
                  this.$store.commit(
                    "incidents/viewSetIncidentDetail",
                    this.data
                  );
                  this.$store.commit("incidents/viewShow");
                }
              },
            },
          }),
        };
      },

      producerTemplate: function () {
        return {
          template: Vue.component("columnTemplate", {
            template: `<strong class="d-block text-truncate">{{ getProducerName() }}</strong>`,

            methods: {
              getProducerName() {
                return get(
                  this.data,
                  "ppm.lot.productproducer.producer.role.name"
                );
              },
            },
          }),
        };
      },

      totalTemplate: function () {
        return {
          template: Vue.component("columnTemplate", {
            template: `<div :class="{ opacity50: disabled }">
              <div v-if="hasIncident()" class="ui-type-heading text-right strikethrough">
                {{ getPriceOrig() }}
              </div>
              <div
                class="ui-type-heading text-right"
                :class="{ strikethrough: strikeThrough }"
              >
                {{ getPrice() }}
              </div>
            </div>`,

            computed: {
              disabled() {
                return this.data.status === ORDER_STATUS.CANCELED;
              },

              strikeThrough() {
                return this.data.status === ORDER_STATUS.CANCELED;
              },

              ...mapGetters("connections", ["currencySymbol"]),
            },

            methods: {
              getPrice() {
                const price = get(this.data, "price", null);
                return price !== null
                  ? formatPrice(price, this.currencySymbol).format()
                  : "";
              },

              hasIncident() {
                return get(this.data, "incident", null) !== null;
              },

              getPriceOrig() {
                const price = get(
                  this.data,
                  "incident.detail.orig_price",
                  null
                );
                return price !== null
                  ? formatPrice(price, this.currencySymbol).format()
                  : "";
              },
            },
          }),
        };
      },
    };
  },

  watch: {
    show() {
      // reset on open/close modal
      this.reset();
    },
  },

  computed: {
    title() {
      return this.$t("family.orders.message.title");
    },

    show() {
      return this.$store.state.messages.create.show;
    },

    order() {
      return this.$store.state.messages.create.order;
    },

    details() {
      return this.$store.state.messages.create.details;
    },

    lang: function () {
      return this.$i18n.locale;
    },

    user() {
      return this.$auth.user() || null;
    },
    isButtonDisabled() {
      if (this.messageType === "NULL") {
        return true;
      } else {
        return false;
      }
    },
  },

  methods: {
    headerCellInfo(args) {
      // remove grid column header checkbox
      const el = args.node.getElementsByClassName("e-checkbox-wrapper")[0];
      el && el.remove();
    },

    reset() {
      this.loading = false;
      this.error = null;
      this.message = "";
    },

    close() {
      this.$store.commit("messages/closeModal");
    },

    onSubmit() {
      this.error = null;

      const detailsId =
        this.messageType === "PRODUCTS"
          ? this.$refs.grid.getSelectedRecords().map((item) => item.id)
          : this.details.map((item) => item.id);

      if (detailsId.length === 0) {
        this.error = this.$t("family.orders.message.msg_noproducts");
        return;
      }

      const formData = {
        familyId: this.order.family.id,
        message: this.message,
        messageType: this.messageType,
        details: detailsId,
      };

      this.loading = true;
      this.$store
        .dispatch("messages/createMessage", formData)
        .then(() => {
          EventBus.$emit("messages:created", this.order);
          this.$store.commit("messages/resetState");
        })
        .catch((error) => {
          this.error = error.response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    haveFieldError(field) {
      return get(this.error, `errors.fields.${field}`, null) !== null;
    },

    getFieldError(field) {
      return get(this.error, `errors.fields.${field}`, null);
    },
  },
};
</script>

<style scoped>
.details-table-wrapper {
  max-height: 300px;
  overflow: scroll;
}
</style>
